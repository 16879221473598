import { Location } from "../../../types/minecraft";
import { validateLocation, validateStringField } from "../../../utils/validation";
var TeleportEntityStage = /** @class */ (function () {
    function TeleportEntityStage(entity, location) {
        this.name = "TeleportEntity";
        this.entity = entity;
        this.location = location;
    }
    TeleportEntityStage.prototype.toJson = function () {
        return [
            this.entity,
            this.location.x,
            this.location.y,
            this.location.z,
            this.location.yaw,
            this.location.pitch
        ];
    };
    return TeleportEntityStage;
}());
var TeleportEntityStageInterpreter = /** @class */ (function () {
    function TeleportEntityStageInterpreter() {
        this.blockType = "teleport_entity_stage";
    }
    TeleportEntityStageInterpreter.prototype.interpret = function (block) {
        return new TeleportEntityStage(block.getFieldValue("entity"), Location.fromBlock(block));
    };
    TeleportEntityStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "entity")
            && validateLocation(block);
    };
    return TeleportEntityStageInterpreter;
}());
export { TeleportEntityStage, TeleportEntityStageInterpreter };
