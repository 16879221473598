import { validateEnumeration, validateStringField } from "../../../utils/validation";
var ClickEntityStage = /** @class */ (function () {
    function ClickEntityStage(key, clickType) {
        this.name = "ClickEntity";
        this.key = key;
        this.clickType = clickType;
    }
    ClickEntityStage.prototype.toJson = function () {
        if (this.clickType === "left")
            return this.key;
        return [this.key, this.clickType];
    };
    return ClickEntityStage;
}());
var ClickEntityStageInterpreter = /** @class */ (function () {
    function ClickEntityStageInterpreter() {
        this.blockType = "click_entity_stage";
    }
    ClickEntityStageInterpreter.prototype.interpret = function (block) {
        return new ClickEntityStage(block.getFieldValue("key"), block.getFieldValue("click_type"));
    };
    ClickEntityStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "key")
            && validateEnumeration(block, "click_type", ["left", "right"]);
    };
    return ClickEntityStageInterpreter;
}());
export { ClickEntityStage, ClickEntityStageInterpreter };
