import { Pokemon } from "../../../types/pokefind";
import { validateBlockInputPresence, validateLocation, } from "../../../utils/validation";
import { Location } from "../../../types/minecraft";
var BattleWildPokemonStage = /** @class */ (function () {
    function BattleWildPokemonStage(location, pokemon) {
        this.name = "BattleWildPokemon";
        this.location = location;
        this.pokemon = pokemon;
    }
    BattleWildPokemonStage.prototype.toJson = function () {
        return [
            this.location.x,
            this.location.y,
            this.location.z,
            this.location.yaw,
            this.pokemon.toJson()
        ];
    };
    return BattleWildPokemonStage;
}());
var BattleWildPokemonStageInterpreter = /** @class */ (function () {
    function BattleWildPokemonStageInterpreter() {
        this.blockType = "battle_wild_pokemon_stage";
    }
    BattleWildPokemonStageInterpreter.prototype.interpret = function (block) {
        var pokemonBlock = block.getInputTargetBlock("pokemon");
        return new BattleWildPokemonStage(Location.fromBlock(block), Pokemon.fromBlock(pokemonBlock));
    };
    BattleWildPokemonStageInterpreter.prototype.validate = function (block) {
        return validateLocation(block)
            && validateBlockInputPresence(block, "pokemon")
            && Pokemon.validate(block.getInputTargetBlock("pokemon"));
    };
    return BattleWildPokemonStageInterpreter;
}());
export { BattleWildPokemonStage, BattleWildPokemonStageInterpreter };
