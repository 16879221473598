import { Vector } from "../../../types/minecraft";
import { TextInterpreter } from "../../blockly/textInterpreter";
import { validateVector } from "../../../utils/validation";
var GlobalHologramStage = /** @class */ (function () {
    function GlobalHologramStage(location, lines) {
        this.name = "GlobalHologram";
        this.location = location;
        this.lines = lines;
    }
    GlobalHologramStage.prototype.toJson = function () {
        var json = [
            this.location.x,
            this.location.y,
            this.location.z
        ];
        this.lines.forEach(function (value) { return json.push(value); });
        return json;
    };
    return GlobalHologramStage;
}());
var GlobalHologramStageInterpreter = /** @class */ (function () {
    function GlobalHologramStageInterpreter() {
        this.blockType = "global_hologram_stage";
    }
    GlobalHologramStageInterpreter.prototype.interpret = function (block) {
        return new GlobalHologramStage(Vector.fromBlock(block), TextInterpreter.interpretList(block.getInputTargetBlock("messages")));
    };
    GlobalHologramStageInterpreter.prototype.validate = function (block) {
        return validateVector(block)
            && TextInterpreter.validateList(block, block.getInputTargetBlock("messages"));
    };
    return GlobalHologramStageInterpreter;
}());
export { GlobalHologramStageInterpreter, GlobalHologramStage };
