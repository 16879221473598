import {createContext, FC, useContext, useEffect, useState} from "react";
import {WithChildren} from "../../utils/utils";
import {initialScriptContext, Script, ScriptContextProps} from "./ScriptModel";
import {useQuery} from "react-query";
import {QUERIES} from "../../utils/consts";
import {toast} from "react-toastify";
import {deleteScript, findScriptByName, findScriptsByAccountId, saveScript as updateScript} from "./_requests";
import {useSessionContext} from "../sessions/SessionContextProvider";
import {useEditorContext} from "../editor/EditorContextProvider";
import Blockly from "blockly";
import CreateScriptComponent from "../components/CreateScriptComponent";

const ScriptContext = createContext<ScriptContextProps>(initialScriptContext);

const ScriptContextProvider: FC<WithChildren> = ({children}) => {
    const {session, hasSession, isLoading: isSessionLoading, isIpLoading} = useSessionContext()
    const {loadScript, interpret, workspace, scriptState, setScriptState} = useEditorContext()

    const [currentScript, setCurrentScript] = useState<Script | null>(null);
    const [scripts, setScripts] = useState<Script[]>([]);

    const getCurrentScriptName = (): string => {
        return localStorage.getItem("current_script") || ""
    }

    const hasCurrentScript = (): boolean => {
        const currentScriptName = getCurrentScriptName()
        return currentScriptName !== null
            && currentScriptName !== ""
            && currentScriptName.length > 0
            && scripts.map(s => s.name).includes(currentScriptName)
    }

    const {
        remove: invalidateScripts,
        isLoading: areScriptsLoading,
    } = useQuery(QUERIES.GET_SCRIPTS, () => findScriptsByAccountId(session?.account_id as string), {
        cacheTime: 600,
        enabled: hasSession(),
        onError: (err: any) => toast.dark(`Failed to get your scripts: ${err}`, {icon: '🚫'}),
        onSuccess: (data) => {
            if (data) {
                setScripts(data)

                if (!hasCurrentScript()) {
                    if (data.length > 0) {
                        setScript(data[0])
                    } else {
                        setScript(null)
                    }
                }
                return;
            }
            toast.dark("Failed to get your scripts", {icon: '🚫'})
        }
    });

    const {
        isLoading
    } = useQuery(QUERIES.GET_SCRIPT, () => findScriptByName(getCurrentScriptName()), {
        cacheTime: 30,
        enabled: !areScriptsLoading && currentScript !== null && hasCurrentScript() && hasSession(),
        onError: (err) => toast.dark(`Failed to get script: "${err}"`, {icon: '🚫'}),
        onSuccess: (data) => {
            if (data)
                setCurrentScript(data)
            else
                toast.dark(`Failed to get script`, {icon: '🚫'})
        }
    });

    const setScript = (script: Script | null) => {
        if (script === null)
            localStorage.removeItem("current_script")
        else
            localStorage.setItem("current_script", script.name)

        setCurrentScript(script)
        if (script)
            loadScript(script)
    }

    const createNewScript = async (scriptName: string, blockly: any = {}): Promise<Script> => {
        return new Promise<Script>((resolve, reject) => {
            if (session === null || session === undefined) {
                toast.dark("Failed to create script: No session", {icon: '🚫'})
                reject();
                return
            }

            updateScript(session.account_id, scriptName, JSON.stringify(blockly), "{}")
                .then((scriptId: string) => {
                    invalidateScripts()
                    const newScript = new Script(scriptId, session.account_id, scriptName, JSON.stringify(blockly), "{}");
                    setScript(newScript)
                    resolve(newScript);
                })
                .catch((err) => {
                    toast.dark(`Failed to create script: ${err.message}`, {icon: '🚫'})
                    reject();
                });
        })
    }

    const saveScript = async (blockly: string, pokefind: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!currentScript) {
                toast.dark("Failed to save script: No script selected", {icon: '🚫'})
                reject();
                return;
            }
            setScriptState("saving")
            workspace?.getInjectionDiv().classList.add("blur-md")
            updateScript(currentScript.account_id, currentScript.name, typeof blockly === "object" ? JSON.stringify(blockly) : blockly, pokefind)
                .then(() => {
                    setScriptState("unchanged")
                    toast.dark(`Script "${currentScript.name}" saved!`, {icon: "💾"})
                    resolve();
                })
                .catch((err) => {
                    toast.dark(`Failed to save "${err.message}" script`, {icon: '🚫'})
                    reject();
                })
                .finally(() => workspace?.getInjectionDiv().classList.remove("blur-md"))
        })
    }

    const onScriptDeleted = (script: Script): Promise<void | number | string> => {
        workspace?.getInjectionDiv().classList.add("blur-md")

        return toast.promise(deleteScript(script), {
            pending: `Deleting script "${script.name}"...`,
            success: `Script "${script.name}" deleted!`,
            error: `Failed to delete script "${script.name}"`,
        }, {
            icon: "🗑️"
        }).then(() => {
            if (currentScript?.id === script.id) {
                if (scripts.length > 0) {
                    setScript(scripts.find(s => s.id !== script.id) || null)
                } else {
                    setScript(null)
                }
            }
            invalidateScripts()
        }).finally(() => workspace?.getInjectionDiv().classList.remove("blur-md"))
    }

    useEffect(() => {
        const handler = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = "";

            if (workspace !== null)
                saveScript(JSON.stringify(Blockly.serialization.workspaces.save(workspace)), interpret() || "")
        };

        if (scriptState !== "unchanged" && currentScript) {
            window.addEventListener("beforeunload", handler);
            return () => {
                window.removeEventListener("beforeunload", handler);
            };
        }
        return () => {
        };
    }, [interpret, scriptState, saveScript, workspace, currentScript]);

    return (
        <ScriptContext.Provider value={{
            script: currentScript,
            scripts,
            setScript,
            saveScript,
            newScript: name => createNewScript(name),
            newScriptWithTemplate: (name, template) => createNewScript(name, require(`./../../templates/${template.path}`)),
            isScriptLoading: isLoading,
            areScriptsLoading,
            onScriptDeleted
        }}>
            {children}

            <CreateScriptComponent
                open={(!isLoading && !isIpLoading && !areScriptsLoading && !isSessionLoading && !hasCurrentScript())}
                closable={false}
                createCallback={() => {
                }}
                closeCallback={() => {
                }}
            />
        </ScriptContext.Provider>
    )
}

const useScriptContext = () => useContext(ScriptContext);

export {ScriptContextProvider, useScriptContext};