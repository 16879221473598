import {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {XCircleIcon} from "@heroicons/react/24/outline";
import {useDocumentationContext} from "../documentation/DocumentationContextProvider";

const DocumentationModal = () => {
    const {isStageShown, setShownStage, shownStage} = useDocumentationContext()

    const close = () => setShownStage(null)

    if (!isStageShown || !shownStage)
        return null

    return (
        <Transition show={isStageShown} as={Fragment}>
            <Dialog
                open={isStageShown}
                onClose={close}
                className="fixed inset-0 z-10 overflow-y-auto"
            >
                <div className="min-h-screen px-4 text-center">
                    {/* Background overlay */}
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-30"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-30"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30"/>
                    </Transition.Child>

                    {/* Modal */}
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className="inline-block align-middle max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded">
                            <div className="absolute top-0 right-0 p-4 cursor-pointer">
                                <XCircleIcon
                                    className="h-6 w-6 text-gray-600"
                                    onClick={close}
                                />
                            </div>
                            <Dialog.Title className="text-lg font-semibold mb-2">
                                {shownStage.title}
                            </Dialog.Title>
                            <Dialog.Description className="text-sm mb-4">
                                {shownStage.category}
                            </Dialog.Description>

                            <p className="mb-4">
                                {shownStage.description}
                            </p>

                            {/* Divider */}
                            <hr className="my-4"/>

                            {/* Image */}
                            {shownStage.image && (
                                <div className="mb-4">
                                    <img
                                        src={shownStage.image}
                                        alt="Modal Image"
                                        className="w-full h-auto rounded"
                                    />
                                </div>
                            )}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

export {DocumentationModal}