import {ScriptTemplate} from "../../templates/template";
import React, {FC, useState} from "react";
import TemplateComponent from "./TemplateComponent";
import {useScriptContext} from "../script/ScriptContextProvider";
import {toast} from "react-toastify";
import {Script} from "../script/ScriptModel";

interface CreateScriptProps {
    open: boolean
    closable: boolean
    createCallback: (script: Script) => void
    closeCallback: () => void
}

const CreateScriptComponent: FC<CreateScriptProps> = ({createCallback, closeCallback, open, closable}) => {
    const {newScriptWithTemplate, scripts} = useScriptContext()

    const [step, setStep] = useState(1)
    const [validationError, setValidationError] = useState<string | null>('')
    const [scriptName, setScriptName] = useState<string>('')
    const [template, setTemplate] = useState<ScriptTemplate | null>(null)

    const templates: ScriptTemplate[] = require("./../../templates/templates.json")

    if (!open)
        return null

    const handleNext = () => setStep(step + 1);
    const handlePrevious = () => setStep(step - 1);

    const handleScriptNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setScriptName(value);

        if (value.length === 0 || value === '') {
            setValidationError('Script name cannot be empty');
            return
        } else if (value.match(/[^a-zA-Z-_]/g)) {
            setValidationError('Script name can only contain letters, underscores and dashes');
            return
        } else if (value.length > 32) {
            setValidationError('Script name cannot be longer than 32 characters');
            return
        } else if (scripts?.find(script => script.name.toLowerCase() === scriptName.toLowerCase())) {
            setValidationError('Script with this name already exists')
            return
        }

        setValidationError(null);
    }

    const handleTemplateChange = (selected: ScriptTemplate) => {
        if (selected === template)
            setTemplate(null);
        else
            setTemplate(selected);
    }

    const handleCreate = () => {
        toast.promise(newScriptWithTemplate(scriptName, template as ScriptTemplate), {
            pending: 'Creating script...',
            success: 'Script created successfully!',
            error: 'Failed to create script'
        }, {
            theme: "dark",
            icon: (status) => {
                if (status.type === "default")
                    return "🔄"
                else if (status.type === "success")
                    return "🎉"
                else if (status.type === "error")
                    return "🚨"
                return "🤔"
            }
        }).then(value => {
            createCallback(value)
            closeCallback()
        })
            .finally(() => {
                setStep(1);
                setScriptName('');
                setTemplate(null);
            })
    }

    return (
        <div id="select-modal" tabIndex={-1} style={{zIndex: 299}} aria-hidden="true"
             className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full md:backdrop-blur-lg">
            <div className="relative p-5 w-full max-w-4xl max-h-full mx-auto">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div
                        className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Create a new script
                        </h3>
                        {closable && (
                            <button type="button"
                                    onClick={closeCallback}
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    data-modal-toggle="select-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                     fill="none"
                                     viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        )}
                    </div>
                    <div className="p-4 md:p-5 m-1 grid grid-cols-4">
                        {/* Stepper */}
                        <ol className="relative text-gray-500 border-s border-gray-200 dark:border-gray-500 dark:text-gray-400 ">
                            <li className="mb-10 ms-6">
                                <span
                                    className={`absolute flex items-center justify-center w-8 h-8 rounded-full -start-4 ring-2 ring-white dark:ring-gray-800 ${step > 1 ? 'bg-green-100 dark:bg-green-700' : 'bg-gray-100 dark:bg-gray-700'}`}>
                                    {step > 1 ? (
                                        <svg className="w-3.5 h-3.5 text-green-500 dark:text-green-400"
                                             aria-hidden="true"
                                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                  strokeWidth="2"
                                                  d="M1 5.917 5.724 10.5 15 1.5"/>
                                        </svg>
                                    ) : (
                                        <span className="text-green-500 dark:text-gray-400">1</span>
                                    )}
                            </span>
                                <h3 className="font-medium leading-tight">Name</h3>
                                <p className="text-sm">Define script's name</p>
                            </li>
                            <li className="ms-6">
                                <span
                                    className={`absolute flex items-center justify-center w-8 h-8 rounded-full -start-4 ring-2 ring-white dark:ring-gray-800 ${step > 2 ? 'bg-green-100 dark:bg-green-700' : 'bg-gray-100 dark:bg-gray-700'}`}>
                                     {step > 2 ? (
                                         <svg className="w-3.5 h-3.5 text-gray-500 dark:text-gray-400"
                                              aria-hidden="true"
                                              xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                              viewBox="0 0 20 16">
                                             <path
                                                 d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z"/>
                                         </svg>
                                     ) : (
                                         <span className="text-gray-500 dark:text-gray-400">2</span>
                                     )}
                                </span>
                                <h3 className="font-medium leading-tight">Template</h3>
                                <p className="text-sm">Kickstart your project</p>
                            </li>
                        </ol>

                        {/* Steps */}
                        <div className="relative col-span-3">
                            {step === 1 && (
                                <div className="flex flex-col space-y-4">
                                    <div className="flex flex-col space-y-1">
                                        <label htmlFor="inputText" className="block text-sm font-medium text-white">
                                            Script name
                                        </label>
                                        <input
                                            type="text"
                                            id="inputText"
                                            className={`mt-1 p-2 w-full border border-${validationError ? 'red' : 'gray'}-300 rounded-lg bg-gray-50 focus:ring-${validationError ? 'red' : ' blue'}-500 focus:border-${validationError ? 'red' : ' blue'}-500 dark:bg-gray-700 dark:border-${validationError ? 'red' : 'gray'}-600 dark:placeholder-${validationError ? 'red' : 'gray'}-400 dark:text-white dark:focus:ring-${validationError ? 'red' : ' blue'}-500 dark:focus:border-${validationError ? 'red' : ' blue'}-500 rounded-md text-gray-90`}
                                            value={scriptName}
                                            onChange={handleScriptNameChange}
                                        />
                                        {validationError && (
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500"><span
                                                className="font-medium">Error!</span> {validationError}</p>
                                        )}
                                    </div>
                                </div>
                            )}
                            {step === 2 && (
                                <div className="flex flex-col space-y-4">
                                    <div className="flex flex-col space-y-1">
                                        <p className="text-gray-500 dark:text-gray-400 mb-4">
                                            Select your desired template:
                                        </p>
                                        <ul className="space-y-4 mb-4">
                                            {templates.map((t: ScriptTemplate) => <TemplateComponent
                                                template={t}
                                                selected={t === template}
                                                onClick={handleTemplateChange}/>)}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            {/* Buttons */}
                            <div className="flex justify-end mt-6 space-x-4">
                                {step > 1 && (
                                    <button type="button"
                                            disabled={validationError != null}
                                            onClick={handlePrevious}
                                            className={`inline-flex justify-center px-4 py-2 text-sm font-medium text-white ${validationError == null ? 'hover:bg-gray-800' : 'bg-gray-500 cursor-not-allowed'} border border-transparent rounded-md bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500`}>
                                        Previous
                                    </button>
                                )}
                                {step < 2 && (
                                    <button type="button"
                                            disabled={validationError != null}
                                            onClick={handleNext}
                                            className={`inline-flex justify-center px-4 py-2 text-sm font-medium text-white ${validationError == null ? 'bg-indigo-600 hover:bg-indigo-700' : 'bg-gray-500 cursor-not-allowed'} border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500`}>
                                        Next
                                    </button>
                                )}
                                {step === 2 && (
                                    <button type="button"
                                            onClick={handleCreate}
                                            disabled={template == null}
                                            className={`inline-flex justify-center px-4 py-2 text-sm font-medium text-white ${template != null ? 'bg-indigo-600 hover:bg-indigo-700' : 'bg-gray-500 cursor-not-allowed'} border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500`}>
                                        Create
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateScriptComponent;