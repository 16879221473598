import { Vector } from "../types/minecraft";
function validateStringField(block, field, message) {
    if (message === void 0) { message = "Please enter a value for ".concat(field, "."); }
    var value = block.getFieldValue(field);
    if (value == null || value == "") {
        block.setWarningText(message);
        return false;
    }
    return true;
}
function validateNumberField(block, field, minimum, maximum) {
    var value = block.getFieldValue(field);
    if (value == null || value == "") {
        block.setWarningText("Please enter a value for ".concat(field, "."));
        return false;
    }
    var number = Number(value);
    if (isNaN(number)) {
        block.setWarningText("Please enter a valid number for ".concat(field, "."));
        return false;
    }
    if (number < minimum) {
        block.setWarningText("Please enter a number greater than or equal to ".concat(minimum, " for ").concat(field, "."));
        return false;
    }
    if (number > maximum) {
        block.setWarningText("Please enter a number less than or equal to ".concat(maximum, " for ").concat(field, "."));
        return false;
    }
    return true;
}
function validateBooleanField(block, field, message) {
    if (message === void 0) { message = "Please enter a value for ".concat(field, "."); }
    var value = block.getFieldValue(field);
    if (typeof value === "boolean") {
        block.setWarningText(message);
        return false;
    }
    return true;
}
function validateVector(block, prefix) {
    if (prefix === void 0) { prefix = ''; }
    var x = block.getFieldValue("".concat(prefix, "_x"));
    var y = block.getFieldValue("".concat(prefix, "_y"));
    var z = block.getFieldValue("".concat(prefix, "_z"));
    if (isNaN(Number(x))) {
        block.setWarningText("Please enter a number for ".concat(prefix, "_x."));
        return false;
    }
    if (isNaN(Number(y))) {
        block.setWarningText("Please enter a number for ".concat(prefix, "_y."));
        return false;
    }
    if (isNaN(Number(z))) {
        block.setWarningText("Please enter a number for ".concat(prefix, "_z."));
        return false;
    }
    return true;
}
function validateLocation(block) {
    if (!validateVector(block))
        return false;
    var yaw = block.getFieldValue('yaw');
    if (isNaN(Number(yaw))) {
        block.setWarningText("Please enter a number for yaw.");
        return false;
    }
    return true;
}
function validateRegion(block, prefix) {
    if (prefix === void 0) { prefix = ''; }
    if (!validateVector(block, prefix + 'min_') || !validateVector(block, prefix + 'max_'))
        return false;
    var min = Vector.fromBlock(block, prefix + 'min_');
    var max = Vector.fromBlock(block, prefix + 'max_');
    if (!min || !max) {
        block.setWarningText("Please enter valid numbers for min/max.");
        return false;
    }
    if (min.equals(max)) {
        block.setWarningText("min and max cannot be the same.");
        return false;
    }
    return true;
}
function validateBlockInputPresence(block, inputName, message) {
    if (message === void 0) { message = "Please enter a value for ".concat(inputName, "."); }
    var value = block.getInputTargetBlock(inputName);
    if (!value) {
        block.setWarningText(message);
        return false;
    }
    return true;
}
function validateEnumeration(block, inputName, values) {
    var value = block.getFieldValue(inputName);
    if (value == null || value == "") {
        block.setWarningText("Please enter a value for ".concat(inputName, "."));
        return false;
    }
    if (!values.includes(value.toLowerCase())) {
        block.setWarningText("Invalid value. Allowed values are: ".concat(values.join(", "), "."));
        return false;
    }
    return true;
}
export { validateStringField, validateNumberField, validateBooleanField, validateVector, validateLocation, validateRegion, validateBlockInputPresence, validateEnumeration };
