import { Vector } from "../../../types/minecraft";
import { TextInterpreter } from "../../blockly/textInterpreter";
import { validateVector } from "../../../utils/validation";
var HologramStage = /** @class */ (function () {
    function HologramStage(location, lines) {
        this.name = "Hologram";
        this.location = location;
        this.lines = lines;
    }
    HologramStage.prototype.toJson = function () {
        var json = [
            this.location.x,
            this.location.y,
            this.location.z
        ];
        this.lines.forEach(function (value) { return json.push(value); });
        return json;
    };
    return HologramStage;
}());
var HologramStageInterpreter = /** @class */ (function () {
    function HologramStageInterpreter() {
        this.blockType = "hologram_stage";
    }
    HologramStageInterpreter.prototype.interpret = function (block) {
        return new HologramStage(Vector.fromBlock(block), TextInterpreter.interpretList(block.getInputTargetBlock("NAME")));
    };
    HologramStageInterpreter.prototype.validate = function (block) {
        return validateVector(block)
            && TextInterpreter.validateList(block, block.getInputTargetBlock("NAME"));
    };
    return HologramStageInterpreter;
}());
export { HologramStageInterpreter, HologramStage };
