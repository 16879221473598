import { TextInterpreter } from "../../blockly/textInterpreter";
var BookStage = /** @class */ (function () {
    function BookStage(pages) {
        this.name = "Book";
        this.pages = pages;
    }
    BookStage.prototype.toJson = function () {
        return this.pages;
    };
    return BookStage;
}());
var BookStageInterpreter = /** @class */ (function () {
    function BookStageInterpreter() {
        this.blockType = "book_stage";
    }
    BookStageInterpreter.prototype.interpret = function (block) {
        return new BookStage(TextInterpreter.interpretList(block.getInputTargetBlock("lines")));
    };
    BookStageInterpreter.prototype.validate = function (block) {
        return TextInterpreter.validateList(block, block.getInputTargetBlock("lines"));
    };
    return BookStageInterpreter;
}());
export { BookStage, BookStageInterpreter };
