import { Vector } from "../../../types/minecraft";
import { validateBooleanField, validateEnumeration, validateVector } from "../../../utils/validation";
var ClickBlockStage = /** @class */ (function () {
    function ClickBlockStage(vector, clickType, highlighted) {
        this.name = "ClickBlock";
        this.vector = vector;
        this.clickType = clickType;
        this.highlighted = highlighted;
    }
    ClickBlockStage.prototype.toJson = function () {
        if (this.clickType !== "any")
            return [
                this.vector.x,
                this.vector.y,
                this.vector.z,
                this.clickType,
                this.highlighted
            ];
        else
            return [
                this.vector.x,
                this.vector.y,
                this.vector.z,
                this.highlighted
            ];
    };
    return ClickBlockStage;
}());
var ClickBlockStageInterpreter = /** @class */ (function () {
    function ClickBlockStageInterpreter() {
        this.blockType = "click_block_stage";
    }
    ClickBlockStageInterpreter.prototype.interpret = function (block) {
        return new ClickBlockStage(Vector.fromBlock(block), block.getFieldValue("click_type"), block.getFieldValue("highlighted") === "true");
    };
    ClickBlockStageInterpreter.prototype.validate = function (block) {
        return validateVector(block)
            && validateBooleanField(block, "highlighted")
            && validateEnumeration(block, "click_type", ["any", "left", "right"]);
    };
    return ClickBlockStageInterpreter;
}());
export { ClickBlockStage, ClickBlockStageInterpreter };
