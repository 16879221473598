import {createContext, FC, useContext, useState} from "react";
import {EditorContextProps, initialEditorContext} from "./EditorModel";
import {WithChildren} from "../../utils/utils";
import {WorkspaceSvg} from "react-blockly";
import Blockly from "blockly";
import {BlocklyInterpreter} from "@pokefind/script-interpreter/dist/interpreter";
import {toast} from "react-toastify";
import {Script} from "../script/ScriptModel";

const EditorContext = createContext<EditorContextProps>(initialEditorContext);

const EditorContextProvider: FC<WithChildren> = ({children}) => {
    const interpreter = new BlocklyInterpreter();

    const [workspace, setWorkspace] = useState<WorkspaceSvg | null>(null)
    const [scriptState, setScriptState] = useState<"unchanged" | "modified" | "saving">("unchanged");

    const interpret = (): string | null => {
        if (!workspace)
            throw new Error("No workspace found")

        // Copy workspace
        const workspaceCopy = new Blockly.Workspace();
        Blockly.Xml.domToWorkspace(Blockly.Xml.workspaceToDom(workspace), workspaceCopy);

        // Interpret
        try {
            return interpreter.interpret(workspaceCopy)
        } catch (e: any) {
            toast.error(e.message, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
            });
            return null
        }
    }

    const loadScript = (script: Script) => {
        if (!workspace || !script)
            return

        if (workspace.getAllBlocks().length > 0) {
            workspace?.clear()
            workspace?.clearUndo()
        }
        if (script.blocky) {
            setScriptState("saving")
            console.log("Loading script", script.blocky)
                Blockly.serialization.workspaces.load(JSON.parse(script.blocky), workspace);
            if (workspace.getTopBlocks().length > 0)
                workspace.centerOnBlock(workspace.getTopBlocks()[0].id);
            workspace.render()
            workspace.scrollCenter();
            workspace.zoomToFit();
            setScriptState("unchanged")
        }
    }

    return (
        <EditorContext.Provider value={{
            workspace,
            setWorkspace,
            loadScript,
            interpret,
            scriptState,
            setScriptState
        }}>
            {children}
        </EditorContext.Provider>
    )
}

const useEditorContext = () => useContext(EditorContext);

export {EditorContextProvider, useEditorContext};