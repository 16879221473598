import {BlocklyWorkspace, WorkspaceSvg} from "react-blockly";
import React, {useEffect, useState} from "react";
import Blockly from "blockly";
import {BlocklyInterpreter} from "@pokefind/script-interpreter/dist/interpreter";
import * as BlockDynamicConnection from "@blockly/block-dynamic-connection";
import {DocumentationContextMenu} from "../documentation/DocumentationContextMenu";
import {StrictConnectionChecker} from "../connections/StrictConnectionChecker";
import {shadowBlockConversionChangeListener} from "@blockly/shadow-block-converter";
import {useEditorContext} from "./EditorContextProvider";
import {useThemeContext} from "../theme/ThemeContextProvider";
import {StepType, useTour} from "@reactour/tour";
import {useSessionContext} from "../sessions/SessionContextProvider";
import {useScriptContext} from "../script/ScriptContextProvider";
import {toast} from "react-toastify";
import {useDocumentationContext} from "../documentation/DocumentationContextProvider";
import '@blockly/toolbox-search';

const BlocklyEditor = () => {
    const {setWorkspace, setScriptState, workspace, scriptState} = useEditorContext();
    const {isFullScreen} = useThemeContext()
    const {setSteps, setIsOpen, steps} = useTour()
    const {hasCompletedInitialTour, setHasCompletedInitialTour, isLoading, isIpLoading} = useSessionContext()
    const {script, saveScript, isScriptLoading, areScriptsLoading} = useScriptContext()
    const {setShownStage} = useDocumentationContext()

    const [json, setJson] = useState("")
    const toolboxCategories = require("./../../config/toolbox.json");

    function onJsonChange(newJson: any) {
        if (newJson === null
            || newJson === undefined
            || newJson === ""
            || newJson === "{}")
            return

        setScriptState("modified")
        setJson(newJson)
    }

    function onInject(workspace: WorkspaceSvg) {
        // Dynamic connections
        BlockDynamicConnection.overrideOldBlockDefinitions();

        // Documentation context menu
        const documentation = new DocumentationContextMenu(workspace, setShownStage);
        documentation.register()

        // Connection checkers
        if (!Blockly.registry.hasItem(Blockly.registry.Type.CONNECTION_CHECKER, 'StrictConnectionChecker'))
            Blockly.registry.register(
                Blockly.registry.Type.CONNECTION_CHECKER,
                'StrictConnectionChecker',
                new StrictConnectionChecker(),
            );

        // Shadow blocks
        workspace.addChangeListener(shadowBlockConversionChangeListener);

        // Disable orphan blocks
        workspace.addChangeListener(Blockly.Events.disableOrphans);

        // Disable double start hats
        workspace.addChangeListener(e => {
            if (e.type !== Blockly.Events.BLOCK_CREATE)
                return
            const startBlocks = workspace.getTopBlocks();
            if (startBlocks.length <= 2)
                return;
            const event = e as Blockly.Events.BlockCreate;
            if (event == null || event.blockId == null)
                return;
            const block = workspace.getBlockById(event.blockId);
            if (block?.type === "click_item_stage"
                || block?.type === "click_to_start_stage"
                || block?.type === "house_enter_stage"
                || block?.type === "in_region_stage") {
                e.run(false)
                toast.error("You can only have one start block")
            }
        })

        // Live validation
        const validate = (e: any) => {
            if (e.type !== Blockly.Events.BLOCK_CHANGE
                && e.type !== Blockly.Events.BLOCK_CREATE
                && e.type !== Blockly.Events.BLOCK_DRAG
                && e.type !== Blockly.Events.BLOCK_MOVE
                && e.type !== Blockly.Events.BLOCK_FIELD_INTERMEDIATE_CHANGE) {
                return
            }
            const interpreter = new BlocklyInterpreter();
            interpreter.validateAll(workspace, false)
        }
        workspace.addChangeListener(validate);

        // Tour
        const stepType: StepType[] = [
            {
                selector: '.blocklyToolboxDiv.blocklyNonSelectable',
                content: 'This is the toolbox, you can find all the blocks here. The blocks are divided into categories for better readability.',
                position: "right",
            },
            {
                selector: `.blocklyToolboxContents > div:first-child:first-child:first-child`,
                content: 'The search bar allows you to search for a block. You can search by stage or block name.',
                position: "right"
            },
            {
                selector: `.blocklyToolboxContents > div:nth-child(2)`,
                content: 'The start blocks are the first blocks you need to use in your script. They are the entry point of your script.',
                position: "right"
            },
            {
                selector: '.blocklyToolboxContents > div:nth-child(3)',
                content: 'Conditions are used to check if something is true or false. They are used in if statements or directly in the script depending on the condition.',
                position: "right",
            },
            {
                selector: '.blocklyToolboxContents > div:nth-child(4)',
                content: 'The dummy blocks are used to make non-game related actions. For example they can be used to create a new objective or key stage.',
                position: "right",
            },
            {
                selector: '.blocklyToolboxContents > div:nth-child(5)',
                content: 'Actions are used to make game related actions. For example they can be used to send a message to the player or teleport him.',
                position: "right",
            },
            {
                selector: '.blocklyToolboxContents > div:nth-child(6)',
                content: 'The objects blocks are used to provide additional information to the script. For example they can be used to define a pokemon or a dialog message.',
                position: "right",
            },
            {
                selector: '.blocklyToolboxContents > div:nth-child(8)',
                content: 'The logic statements are used to make logical operations. For example they can be used to check if two conditions are true or false and how to react to it.',
                position: "right",
            },
            {
                selector: '.blocklyToolboxContents > div:nth-child(9)',
                content: 'Math blocks allows you to make mathematical operations. For example they can be used to calculate the level of a pokemon.',
                position: "right",
            },
            {
                selector: '.blocklyToolboxContents > div:nth-child(10)',
                content: 'Text blocks allows you to make text operations. For example they can be used to concatenate two strings, make a substring or create change the case of a string.',
                position: "right",
            },
            {
                selector: '.blocklyToolboxContents > div:nth-child(11)',
                content: 'This last category allows you to create lists. For example they can be used to create a list of pokemon or a list of messages.',
                position: "right",
            }
        ]

        if (!hasCompletedInitialTour && setSteps) {
            setSteps([...stepType, ...steps])
            setIsOpen(true)
            setHasCompletedInitialTour(true)
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (!script
                || !json
                || !workspace
                || scriptState !== 'modified')
                return
            saveScript(json, new BlocklyInterpreter().interpret(workspace) || "{}")
                .catch((e) => toast.error(`Error while saving script: ${e}`))
        }, 300_000);
        return () => clearInterval(interval)
    }, [json, script, saveScript, workspace, scriptState]);


    useEffect(() => {
        if (script && workspace && workspace.getTopBlocks().length > 0)
            workspace.centerOnBlock(workspace.getTopBlocks()[0].id);
        return () => {
        }
    }, [script, workspace]);

    if (isScriptLoading
        || isLoading
        || isIpLoading
        || areScriptsLoading)
        return (
            <div className={"fill-height"}>
                <img src={"./assets/media/workspace.jpg"} alt={"Placeholder image for workspace"}/>
            </div>
        )

    return (
        <BlocklyWorkspace
            toolboxConfiguration={toolboxCategories}
            className={isFullScreen ? "full-height" : "fill-height"}
            initialJson={script?.blocky ? JSON.parse(script.blocky) : undefined}
            workspaceConfiguration={{
                maxBlocks: 150,
                disable: isScriptLoading,
                comments: true,
                trashcan: true,
                toolboxPosition: 'start',
                css: true,
                scrollbars: false,
                sounds: true,
                oneBasedIndex: true,
                grid: {
                    spacing: 20,
                    length: 1,
                    colour: "#888",
                    snap: true,
                },
                rendererOverrides: {
                    startHats: true
                },
                zoom: {
                    controls: true,
                    pinch: true,
                    wheel: true,
                },
                renderer: "thrasos"
            }}
            onJsonChange={onJsonChange}
            onInject={onInject}
            onWorkspaceChange={setWorkspace}
        />
    )
}

export {BlocklyEditor}