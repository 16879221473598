import {useEditorContext} from "../editor/EditorContextProvider";
import {useThemeContext} from "../theme/ThemeContextProvider";
import {useEffect, useRef, useState} from "react";
import Blockly from "blockly";
import {WorkspaceSvg} from "react-blockly";
import {ScriptListComponent} from "./ScriptListComponent";
import {useScriptContext} from "../script/ScriptContextProvider";

const HeaderComponent = () => {
    const {scriptState, setScriptState, interpret, workspace} = useEditorContext()

    const {isFullScreen, toggleFullScreen} = useThemeContext()
    const {script, isScriptLoading, onScriptDeleted, saveScript} = useScriptContext()

    const ref = useRef<HTMLElement | null>(null)
    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)
    const [visible, setVisible] = useState(true)
    const [isDeleting, setDeleting] = useState(false)

    const toggleMenu = () => {
        toggleFullScreen()
        setVisible(isFullScreen)
    }

    const handleDelete = () => {
        if (!script)
            return
        setDeleting(true)
        onScriptDeleted(script)
            .finally(() => setDeleting(false))
    }

    const handleSave = () => {
        setScriptState('saving')
        if (workspace !== null)
            saveScript(JSON.stringify(Blockly.serialization.workspaces.save(workspace)), interpret() || "")
        else
            setScriptState('unchanged')
    }

    useEffect(() => {
        if (ref.current !== null)
            setHeight(ref.current.clientHeight)
        return () => setHeight(0)
    }, [])

    useEffect(() => {
        const handleWindowMouseMove = function (event: { clientX: any; clientY: any; }) {
            if (!isFullScreen)
                return

            if (event.clientY < height && !visible && event.clientX > width)
                setVisible(true)
            else if (event.clientY > height && visible)
                setVisible(false)
        };

        window.addEventListener('mousemove', handleWindowMouseMove);

        return () => window.removeEventListener('mousemove', handleWindowMouseMove);
    }, [height, isFullScreen, visible, width]);

    setTimeout(() => {
        const workspace = Blockly.getMainWorkspace() as WorkspaceSvg
        setWidth(workspace?.getToolbox()?.getWidth() ?? 0)
        if (workspace?.getToolbox()?.getFlyout()?.isVisible())
            setWidth(((workspace?.getToolbox()?.getWidth() ?? 0) + (workspace?.getToolbox()?.getFlyout()?.getWidth() ?? 0)) ?? 0)
    }, 500);

    return (
        <header className={`${isFullScreen && !visible && 'hidden'}`} ref={ref}>
            <nav className="bg-white border-gray-200 px-5 lg:px-6 py-2.5 dark:bg-gray-800">
                <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                    {/* Logo */}
                    <div className="flex items-center">
                        <img src="/assets/media/logo.webp" className="mr-3 h-6 sm:h-9" alt="PokeFind Logo"/>
                        <span
                            className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">PokeFind</span>
                    </div>
                    {/* Buttons */}
                    <div className="flex items-center lg:order-2">
                        {/* Save button */}
                        <button
                            id="save-button"
                            onClick={handleSave}
                            disabled={isScriptLoading || !script || scriptState !== 'modified' || isDeleting}
                            className={`inline-flex items-center mr-2 ${scriptState === 'modified' && 'transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none'} bg-${scriptState === 'modified' ? 'green' : 'gray'}-500 hover:bg-${scriptState === 'modified' ? 'green' : 'gray'}-400 text-white font-bold py-2 px-4 border-b-4 border-${scriptState === 'modified' ? 'green' : 'gray'}-700 hover:border-${scriptState === 'modified' ? 'green' : 'gray'}-500 rounded`}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-5 h-5 mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m0-3l-3-3m0 0l-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"/>
                            </svg>
                            {scriptState === 'saving' ? "Saving..." : "Save"}
                        </button>

                        {/* Script lists button */}
                        <ScriptListComponent/>

                        {/* Delete button */}
                        <button
                            id="delete-button"
                            onClick={handleDelete}
                            disabled={isScriptLoading || !script || isDeleting}
                            className={`ms-2 shadow-2xl cursor-pointer overflow-hidden ${!isDeleting && 'transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'} inline-flex items-center mr-3 text-white font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-${isDeleting ? 'gray' : 'red'}-600 dark:hover:bg-${isDeleting ? 'gray' : 'red'}-700 focus:outline-none dark:focus:ring-${isDeleting ? 'gray' : 'red'}-800`}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-5 h-5 mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                            </svg>
                            {isDeleting ? "Deleting..." : "Delete"}
                        </button>

                        {/* Fullscreen button */}
                        <button
                            id="fullscreen-button"
                            onClick={toggleMenu}
                            className="transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none text-white font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 light:bg-primary-600 light:hover:bg-primary-700 focus:outline-none light:focus:ring-primary-800">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6">
                                {isFullScreen ? (
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"/>
                                ) : (
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"/>
                                )}
                            </svg>
                        </button>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export {HeaderComponent}