var ObjectiveStage = /** @class */ (function () {
    function ObjectiveStage(key, length) {
        this.name = "Objective";
        this.key = key;
        this.length = length;
    }
    ObjectiveStage.prototype.toJson = function () {
        return [
            this.key,
            this.length
        ];
    };
    return ObjectiveStage;
}());
var ObjectiveStageInterpreter = /** @class */ (function () {
    function ObjectiveStageInterpreter() {
        this.blockType = "objective_stage";
    }
    ObjectiveStageInterpreter.prototype.interpret = function (block) {
        var key = block.getFieldValue("key");
        if (!key || key.length === 0)
            throw new Error("Objective stage must have a key");
        // Children
        var children = [];
        var childHeadBlock = block.getInputTargetBlock("objectives");
        if (!childHeadBlock)
            throw new Error("Objective stage must have at least one objective");
        var nextBlock = childHeadBlock;
        while (nextBlock) {
            children.push(nextBlock);
            nextBlock = nextBlock.getNextBlock();
        }
        return new ObjectiveStage(key, children.length);
    };
    return ObjectiveStageInterpreter;
}());
export { ObjectiveStage, ObjectiveStageInterpreter };
