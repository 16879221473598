import { Location } from "../../../types/minecraft";
import { validateLocation, validateNumberField } from "../../../utils/validation";
var CheckpointStage = /** @class */ (function () {
    function CheckpointStage(location, height) {
        this.name = "Checkpoint";
        this.location = location;
        this.height = height;
    }
    CheckpointStage.prototype.toJson = function () {
        return [
            this.location.x,
            this.location.y,
            this.location.z,
            this.location.yaw,
            this.height
        ];
    };
    return CheckpointStage;
}());
var CheckpointStageInterpreter = /** @class */ (function () {
    function CheckpointStageInterpreter() {
        this.blockType = "checkpoint_stage";
    }
    CheckpointStageInterpreter.prototype.interpret = function (block) {
        return new CheckpointStage(Location.fromBlock(block), block.getFieldValue("height"));
    };
    CheckpointStageInterpreter.prototype.validate = function (block) {
        return validateLocation(block)
            && validateNumberField(block, "height", 0, 255);
    };
    return CheckpointStageInterpreter;
}());
export { CheckpointStage, CheckpointStageInterpreter };
